/* CSS for FumaDocs */
@import url("fumadocs-ui/style.css");

.svg-noise::after {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-image: url("/public/images/noise.svg"), url("/images/noise.svg");
	background-size: 40%;
	mix-blend-mode: overlay;
}

#nd-docs-layout article > div.prose {
	--tw-prose-bullets: #3f3f46;
	--tw-prose-counters: #3f3f46;
	@apply prose-h2:mb-2 prose-h2:font-semibold;
	@apply text-lg !leading-7;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Form input error border state  */

form *:has(+ p.error-message) {
	@apply !border-border-error;
}

.cm-focused {
	outline: none !important;
}

.cm-content {
	font-size: 13px;
	font-family: var(--code-font), monospace;
}

.woot-widget-holder {
	bottom: 20px !important;
}

.code {
	font-family: var(--code-font), monospace !important;
	@apply text-md font-normal leading-4;
}

.gradient {
	background-color: #ffdee9;
	background-image: linear-gradient(0deg, #ffdee9 0%, #b5fffc 100%);
}

.display-linebreak {
	white-space: pre-line;
}

.HW_badge {
	position: absolute !important;
	margin-left: 4px !important;
	margin-top: -18px !important;
}
.HW_badge_cont {
	position: absolute !important;
}

.HW_frame_cont {
	margin-top: 5px !important;
}

.HW_softHidden {
	display: none !important;
}

div.HW_frame_cont {
	left: 9px !important;
	margin-top: -30px !important;
}

div.HW_frame_cont.HW_visible {
	margin-top: -40px !important;
	min-height: 500px !important;
	height: 500px !important;
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background-primary text-content-primary;
		font-family: var(--font-inter);
	}

	h1 {
		@apply text-4xl font-semibold leading-12;
	}
	h2 {
		@apply text-3xl font-semibold leading-9;
	}
	h3 {
		@apply text-base font-semibold leading-6;
	}
	h4 {
		@apply text-lg font-semibold leading-5;
	}
	h5 {
		@apply text-md font-semibold leading-5;
	}

	.font-brand {
		font-family: var(--font-grotesque);
	}
}

/* Charts */
@layer base {
	:root {
		--timeline-color: #cdcdd1;

		/* Original colors */
		--chart-1: 248, 28%, 43%;
		--chart-2: 324, 45%, 53%;
		--chart-3: 39, 100%, 61%;
		--chart-4: 22, 86%, 52%;
		--chart-5: 155, 64%, 43%;
		--chart-6: 199, 64%, 50%;
		--chart-7: 29, 84%, 36%;
		--chart-8: 60, 0%, 54%;
		--chart-green: 212, 97%, 87%;

		/* Additional colors */
		--chart-9: 300, 100%, 25%;
		--chart-10: 60, 100%, 50%;
		--chart-11: 30, 100%, 50%;
		--chart-12: 270, 100%, 50%;
		--chart-13: 90, 100%, 50%;
		--chart-14: 330, 100%, 50%;
		--chart-15: 150, 100%, 50%;
		--chart-16: 210, 100%, 50%;
		--chart-17: 0, 0%, 75%;
		--chart-18: 0, 0%, 25%;
		--chart-19: 45, 100%, 50%;
		--chart-20: 135, 100%, 50%;
		--chart-21: 225, 100%, 50%;
		--chart-22: 315, 100%, 50%;
		--chart-23: 15, 100%, 50%;
		--chart-24: 105, 100%, 50%;
		--chart-25: 195, 100%, 50%;
		--chart-26: 285, 100%, 50%;
		--chart-27: 75, 100%, 50%;
		--chart-28: 165, 100%, 50%;
		--chart-29: 255, 100%, 50%;
		--chart-30: 345, 100%, 50%;
	}
}

/* shadcn sidebar */
@layer base {
	:root {
		--sidebar-background: 0 0% 98%;
		--sidebar-foreground: 240 5.3% 26.1%;
		--sidebar-primary: 240 5.9% 10%;
		--sidebar-primary-foreground: 0 0% 98%;
		--sidebar-accent: 240 4.8% 95.9%;
		--sidebar-accent-foreground: 240 5.9% 10%;
		--sidebar-border: 220 13% 91%;
		--sidebar-ring: 217.2 91.2% 59.8%;
	}

	.dark {
		--sidebar-background: 240 5.9% 10%;
		--sidebar-foreground: 240 4.8% 95.9%;
		--sidebar-primary: 224.3 76.3% 48%;
		--sidebar-primary-foreground: 0 0% 100%;
		--sidebar-accent: 240 3.7% 15.9%;
		--sidebar-accent-foreground: 240 4.8% 95.9%;
		--sidebar-border: 240 3.7% 15.9%;
		--sidebar-ring: 217.2 91.2% 59.8%;
	}
}

* {
	overscroll-behavior: none;
}

.no-scrollbar {
	-ms-overflow-style: none !important; /* IE and Edge */
	scrollbar-width: none !important; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
	display: none !important;
}

/* This is for login page */

.section-bg {
	background-color: #0b151e !important;
	background-image: linear-gradient(rgba(12, 48, 54, 0.2), rgba(12, 48, 54, 0.2));
}

.bg-pattern {
	background-image: url(/public/images/hero-pattern.svg), url(/public/images/hero-gradient.png);
	background-position:
		50% 100%,
		50% 100%;
	background-repeat: no-repeat, no-repeat;
	background-size: auto, auto;
}

/*  This are custom grid class for  */

.test-run-custom-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 1.33333%;
	justify-content: space-between;
}

.test-run-custom-grid > *:nth-child(n + 1) {
	width: 49.3%;
	margin-top: 0;
	height: 302px !important;
}

.test-run-custom-grid > *:nth-child(n + 3) {
	width: 32%;
	margin-top: 16px;
	height: 205px !important;
}

/* CSS for custom scrollbar */
.custom-scrollbar {
	overflow: auto;
	scrollbar-width: thin; /* Firefox */
	scrollbar-color: rgba(228, 228, 231, 1) transparent; /* Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
	--custom-scrollbar-width: 8px;
	--custom-scrollbar-height: 8px;
	width: var(--custom-scrollbar-width, 8px);
	height: var(--custom-scrollbar-height, 8px);
	touch-action: none;
}

.custom-scrollbar::-webkit-scrollbar-track {
	background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	--tw-bg-opacity: 1 !important;
	background-color: rgba(228, 228, 231, var(--tw-bg-opacity)) !important;
	border-radius: 8px;
	opacity: 0;
	visibility: hidden;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb {
	opacity: 1;
	visibility: visible;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
	--tw-bg-opacity: 1 !important;
	background-color: rgba(82, 82, 91, var(--tw-bg-opacity)) !important;
}

/* For older WebKit browsers */
.custom-scrollbar::-webkit-scrollbar-thumb:horizontal {
	background-color: rgba(228, 228, 231, var(--tw-bg-opacity)) !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb:vertical {
	background-color: rgba(228, 228, 231, var(--tw-bg-opacity)) !important;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb:horizontal {
	background-color: rgba(82, 82, 91, var(--tw-bg-opacity)) !important;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb:vertical {
	background-color: rgba(82, 82, 91, var(--tw-bg-opacity)) !important;
}

/* Group hover */

.group-hover\/inner1:hover .group-hover\/inner1\:block,
.group-hover\/inner2:hover .group-hover\/inner2\:block,
.group-hover\/inner3:hover .group-hover\/inner3\:block,
.group-hover\/inner4:hover .group-hover\/inner4\:block,
.group-hover\/inner5:hover .group-hover\/inner5\:block {
	display: block !important;
}

aside[data-state="expanded"] + main#main-content {
	padding-left: 200px !important;
}

aside[data-state="collapsed"] + main#main-content {
	padding-left: 66px !important;
}

main#main-content aside[data-open="false"] {
	--tw-bg-opacity: 1 !important;
	background-color: rgb(250 250 250 / var(--tw-bg-opacity)) !important;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *))::after {
	content: "" !important;
}

.mtk10,
.mtk23 {
	@apply border-y border-content-brand-light bg-background-brand-light/50 px-[2px];
}

.mtk23:has(+ .mtk10) {
	@apply rounded-l-sm border-l;
}

.mtk23:not(:has(+ .mtk10)) {
	@apply rounded-r-sm border-r;
}

.gdg-r17m35ur {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	border: none;
	outline: none;
	background-color: var(--gdg-bg-header-has-focus);
	border-radius: 9px;
	padding: 0 8px;
	box-shadow: 0 0 0 1px var(--gdg-border-color);
	color: var(--gdg-text-group-header);
	min-height: var(--r17m35ur-0);
	font: var(--gdg-header-font-style) var(--gdg-font-family);
}
.gdg-c1tqibwd {
	padding: 0 8px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: var(--gdg-bg-header);
}
.gdg-gzpuzkl {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	border-radius: 0px;
	resize: none;
	white-space: pre-wrap;
	min-width: 100%;
	overflow: hidden;
	border: 0;
	background-color: transparent;
	font-size: var(--gdg-editor-font-size);
	line-height: 16px;
	font-family: var(--gdg-font-family);
	-webkit-text-fill-color: var(--gdg-text-dark);
	color: var(--gdg-text-dark);
	padding: 0;
	margin: 0;
}
.gdg-gzpuzkl::-webkit-input-placeholder {
	color: var(--gdg-text-light);
}
.gdg-gzpuzkl::-moz-placeholder {
	color: var(--gdg-text-light);
}
.gdg-gzpuzkl:-ms-input-placeholder {
	color: var(--gdg-text-light);
}
.gdg-gzpuzkl::placeholder {
	color: var(--gdg-text-light);
}
.gdg-invalid .gdg-gzpuzkl {
	-webkit-text-decoration: underline;
	text-decoration: underline;
	-webkit-text-decoration-color: #d60606;
	text-decoration-color: #d60606;
}
.gdg-g69h75o {
	visibility: hidden;
	white-space: pre-wrap;
	word-wrap: break-word;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	max-width: 100%;
	min-width: 100%;
	font-size: var(--gdg-editor-font-size);
	line-height: 16px;
	font-family: var(--gdg-font-family);
	color: var(--gdg-text-dark);
	padding: 0;
	margin: 0;
	padding-bottom: 2px;
}
.gdg-g1y0xocz {
	position: relative;
	margin-top: 6px;
}
.gdg-wmyidgi {
	position: relative;
	min-width: 10px;
	min-height: 36px;
	max-width: 100%;
	max-height: 100%;
	width: var(--wmyidgi-0);
	height: var(--wmyidgi-1);
	overflow: hidden;
	overflow: clip;
	direction: ltr;
}
.gdg-wmyidgi > :first-child {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.gdg-deveqep {
	position: absolute;
	top: 4px;
	right: 20px;
	background-color: var(--gdg-bg-cell);
	color: var(--gdg-text-dark);
	padding: 8px;
	border: 1px solid var(--gdg-border-color);
	border-radius: 6px;
	font-size: var(--gdg-editor-font-size);
	-webkit-animation: gdg-search-fadein-gdg-deveqep 0.15s forwards;
	animation: gdg-search-fadein-gdg-deveqep 0.15s forwards;
}
.gdg-deveqep.out {
	-webkit-animation: gdg-search-fadeout-gdg-deveqep 0.15s forwards;
	animation: gdg-search-fadeout-gdg-deveqep 0.15s forwards;
}
.gdg-deveqep .gdg-search-bar-inner {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}
.gdg-deveqep .gdg-search-status {
	padding-top: 4px;
	font-size: 11px;
}
.gdg-deveqep .gdg-search-progress {
	position: absolute;
	height: 4px;
	left: 0;
	bottom: 0;
	background-color: var(--gdg-text-light);
}
.gdg-deveqep input {
	width: 220px;
	color: var(--gdg-textDark);
	background-color: var(--gdg-bg-cell);
	border: none;
	border-width: 0;
	outline: none;
}
.gdg-deveqep button {
	width: 24px;
	height: 24px;
	padding: 0;
	border: none;
	outline: none;
	background: none;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
	color: var(--gdg-text-medium);
}
.gdg-deveqep button:hover {
	color: var(--gdg-text-dark);
}
.gdg-deveqep button .button-icon {
	width: 16px;
	height: 16px;
}
.gdg-deveqep button:disabled {
	opacity: 0.4;
	pointer-events: none;
}
@-webkit-keyframes gdg-search-fadeout-gdg-deveqep {
	from {
		-webkit-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
	}
	to {
		-webkit-transform: translateX(400px);
		-ms-transform: translateX(400px);
		transform: translateX(400px);
	}
}
@keyframes gdg-search-fadeout-gdg-deveqep {
	from {
		-webkit-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
	}
	to {
		-webkit-transform: translateX(400px);
		-ms-transform: translateX(400px);
		transform: translateX(400px);
	}
}
@-webkit-keyframes gdg-search-fadein-gdg-deveqep {
	from {
		-webkit-transform: translateX(400px);
		-ms-transform: translateX(400px);
		transform: translateX(400px);
	}
	to {
		-webkit-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
	}
}
@keyframes gdg-search-fadein-gdg-deveqep {
	from {
		-webkit-transform: translateX(400px);
		-ms-transform: translateX(400px);
		transform: translateX(400px);
	}
	to {
		-webkit-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
	}
}
.gdg-s1dgczr6 .dvn-scroller {
	overflow: var(--s1dgczr6-0);
	-webkit-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
.gdg-s1dgczr6 .dvn-hidden {
	visibility: hidden;
}
.gdg-s1dgczr6 .dvn-scroll-inner {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	pointer-events: none;
}
.gdg-s1dgczr6 .dvn-scroll-inner > * {
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.gdg-s1dgczr6 .dvn-scroll-inner .dvn-spacer {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.gdg-s1dgczr6 .dvn-scroll-inner .dvn-stack {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}
.gdg-s1dgczr6 .dvn-underlay > * {
	position: absolute;
	left: 0;
	top: 0;
}
.gdg-s1dgczr6 canvas {
	outline: none;
}
.gdg-s1dgczr6 canvas * {
	height: 0;
}
.gdg-mnuv029 {
	word-break: break-word;
	-webkit-touch-callout: default;
	padding-top: 6px;
}
.gdg-mnuv029 > * {
	margin: 0;
}
.gdg-mnuv029 *:last-child {
	margin-bottom: 0;
}
.gdg-mnuv029 p img {
	width: 100%;
}
.gdg-d19meir1 {
	position: absolute;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	overflow: hidden;
	box-sizing: border-box;
	--overlay-top: var(--d19meir1-0);
	left: var(--d19meir1-1);
	top: var(--d19meir1-0);
	min-width: var(--d19meir1-2);
	min-height: var(--d19meir1-3);
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	max-width: 400px;
	max-height: calc(100vh - var(--d19meir1-4));
	font-family: var(--gdg-font-family);
	font-size: var(--gdg-editor-font-size);
	text-align: start;
}
@-webkit-keyframes glide_fade_in-gdg-d19meir1 {
	from {
		opacity: 0%;
	}
	to {
		opacity: 100%;
	}
}
@keyframes glide_fade_in-gdg-d19meir1 {
	from {
		opacity: 0%;
	}
	to {
		opacity: 100%;
	}
}
.gdg-d19meir1.gdg-style {
	border-radius: 2px;
	background-color: var(--gdg-bg-cell);
	box-shadow:
		0 0 0 1px var(--gdg-accent-color),
		0px 0px 1px rgba(62, 65, 86, 0.4),
		0px 6px 12px rgba(62, 65, 86, 0.15);
	-webkit-animation: glide_fade_in-gdg-d19meir1 60ms 1;
	animation: glide_fade_in-gdg-d19meir1 60ms 1;
}
.gdg-d19meir1.gdg-pad {
	padding: var(--d19meir1-5) 8.5px 3px;
}
.gdg-d19meir1 .gdg-clip-region {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
	border-radius: 2px;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.gdg-d19meir1 .gdg-clip-region .gdg-growing-entry {
	height: 100%;
}
.gdg-d19meir1 .gdg-clip-region input.gdg-input {
	width: 100%;
	border: none;
	border-width: 0;
	outline: none;
}
.gdg-d19meir1 .gdg-clip-region textarea.gdg-input {
	border: none;
	border-width: 0;
	outline: none;
}
.gdg-d4zsq0x {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.gdg-d4zsq0x .doe-bubble {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 8px;
	height: 24px;
	background-color: var(--gdg-bg-cell);
	color: var(--gdg-text-dark);
	margin: 2px;
	border-radius: var(--gdg-rounding-radius, 6px);
	box-shadow:
		0 0 1px rgba(62, 65, 86, 0.4),
		0 1px 3px rgba(62, 65, 86, 0.4);
}
.gdg-d4zsq0x .doe-bubble img {
	height: 16px;
	object-fit: contain;
	margin-right: 4px;
}
.gdg-d4zsq0x textarea {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 0px;
	height: 0px;
	opacity: 0;
}
.gdg-u1rrojo {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	min-height: 21px;
}
.gdg-u1rrojo .gdg-link-area {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-shrink: 1;
	-ms-flex-negative: 1;
	flex-shrink: 1;
	cursor: pointer;
	margin-right: 8px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: var(--gdg-link-color);
	-webkit-text-decoration: underline !important;
	text-decoration: underline !important;
}
.gdg-u1rrojo .gdg-edit-icon {
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: 32px;
	color: var(--gdg-accent-color);
	cursor: pointer;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.gdg-u1rrojo .gdg-edit-icon > * {
	width: 24px;
	height: 24px;
}
.gdg-u1rrojo textarea {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 0px;
	height: 0px;
	opacity: 0;
}
.gdg-i2iowwq {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
}
.gdg-i2iowwq .gdg-centering-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
}
.gdg-i2iowwq .gdg-centering-container img,
.gdg-i2iowwq .gdg-centering-container canvas {
	max-height: calc(100vh - var(--overlay-top) - 20px);
	object-fit: contain;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.gdg-i2iowwq .gdg-centering-container canvas {
	max-width: 380px;
}
.gdg-i2iowwq .gdg-edit-icon {
	position: absolute;
	top: 12px;
	right: 0;
	width: 48px;
	height: 48px;
	color: var(--gdg-accent-color);
	cursor: pointer;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.gdg-i2iowwq .gdg-edit-icon > * {
	width: 24px;
	height: 24px;
}
.gdg-i2iowwq textarea {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 0px;
	height: 0px;
	opacity: 0;
}
.gdg-b1ygi5by {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-top: auto;
	margin-bottom: auto;
}
.gdg-b1ygi5by .boe-bubble {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: var(--gdg-rounding-radius, 10px);
	padding: 0 8px;
	height: 20px;
	background-color: var(--gdg-bg-bubble);
	color: var(--gdg-text-dark);
	margin: 2px;
}
.gdg-b1ygi5by textarea {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 0px;
	height: 0px;
	opacity: 0;
}
.gdg-n15fjm3e {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin: 6px 0 3px;
	color: var(--gdg-text-dark);
}
.gdg-n15fjm3e > input {
	font-size: var(--gdg-editor-font-size);
	padding: 0;
	font-family: var(--gdg-font-family);
	color: var(--gdg-text-dark);
	background-color: var(--gdg-bg-cell);
}
.gdg-m1pnx84e {
	min-width: var(--m1pnx84e-0);
	width: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: flex-start;
	-webkit-box-align: flex-start;
	-ms-flex-align: flex-start;
	align-items: flex-start;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	position: relative;
	color: var(--gdg-text-dark);
}
.gdg-m1pnx84e .gdg-g1y0xocz {
	-webkit-flex-shrink: 1;
	-ms-flex-negative: 1;
	flex-shrink: 1;
	min-width: 0;
}
.gdg-m1pnx84e .gdg-spacer {
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}
.gdg-m1pnx84e .gdg-edit-icon {
	position: relative;
	cursor: pointer;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: var(--gdg-accent-color);
	padding: 0;
	height: 24px;
	width: 24px;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-webkit-transition: all "0.125s ease";
	transition: all "0.125s ease";
	border-radius: 6px;
}
.gdg-m1pnx84e .gdg-edit-icon > * {
	width: 16px;
	height: 16px;
}
.gdg-m1pnx84e .gdg-edit-hover:hover {
	background-color: var(--gdg-accent-light);
	-webkit-transition: background-color 150ms;
	transition: background-color 150ms;
}
.gdg-m1pnx84e .gdg-checkmark-hover:hover {
	color: #ffffff;
	background-color: var(--gdg-accent-color);
}
.gdg-m1pnx84e .gdg-md-edit-textarea {
	position: relative;
	top: 0px;
	left: 0px;
	width: 0px;
	height: 0px;
	margin-top: 25px;
	opacity: 0;
	padding: 0;
}
.gdg-m1pnx84e .gdg-ml-6 {
	margin-left: 6px;
}

table.csv-import-preview {
	table-layout: fixed;
	width: 100%;
}

table.csv-import-preview th,
table.csv-import-preview td {
	width: calc(100% / 3);
}

table.csv-import-preview th,
table.csv-import-preview td {
	width: auto;
}

table.csv-import-preview th:nth-child(n + 4),
table.csv-import-preview td:nth-child(n + 4),
table.csv-import-preview th:nth-child(-n + 3),
table.csv-import-preview td:nth-child(-n + 3) {
	width: 200px;
}

/* CSS for Testrun Companions view */

/* Add a bottom border to rows that are followed by a non-shadow row */
table.testrun-report-table tr:has(+ tr[data-is-shadow-row="false"]) {
	@apply !border-border;
	border-bottom-width: 1px !important;
	border-style: solid !important;
}

/* Make the text transparent and non-selectable for input and expectedOutput columns in shadow rows */
table.testrun-report-table tr[data-is-shadow-row="true"] > td[data-column-id="input"],
table.testrun-report-table tr[data-is-shadow-row="true"] > td[data-column-id="expectedOutput"] {
	color: transparent !important;
	user-select: none !important;
}

/* Add a transition effect to all table cells for smooth color changes */
table.testrun-report-table tr > td {
	@apply transition-colors;
}

/* Change background color of table cells on hover */
table.testrun-report-table tr:hover > td {
	@apply transition-colors;
	background-color: rgb(246, 246, 247) !important;
}

/* Style rows and cells that contain a checked checkbox */
table.testrun-report-table tr:has(button[role="checkbox"][data-state="checked"]),
table.testrun-report-table tr:has(button[role="checkbox"][data-state="checked"]) > td {
	background-color: rgb(246, 246, 247) !important;
}

/* Tanstack Table resizer */
@media (hover: hover) {
	.col-hover {
		opacity: 0;
	}

	*:hover > .col-hover {
		opacity: 1;
	}
}

@keyframes stripe {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 20px 0;
  }
}

.animate-stripe {
  animation: stripe 1s linear infinite;
}

/* Workflow turn delete button animation, do not remove without proper reason/review */
div.workflow-turn:has(button.turn-delete-button:hover) {
	@apply bg-red-200 !important; 
}
div.workflow-turn:has(button.turn-delete-button:hover) > * {
	@apply border-transparent !important;
}